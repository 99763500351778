import { render, staticRenderFns } from "./SPLSidebar.vue?vue&type=template&id=19c9a7f2&scoped=true"
import script from "./SPLSidebar.vue?vue&type=script&lang=js"
export * from "./SPLSidebar.vue?vue&type=script&lang=js"
import style0 from "./SPLSidebar.vue?vue&type=style&index=0&id=19c9a7f2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19c9a7f2",
  null
  
)

export default component.exports